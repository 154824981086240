/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/* An enumeration. */import {EnumMetadata} from '../metadata'

    /**
    * An enumeration.
    */
export enum UserPriceEstimateErrorState {
    INACTIVE_COVERAGE = 'INACTIVE_COVERAGE',
    SELF_ADMINISTERED = 'SELF_ADMINISTERED',
    OUT_OF_NETWORK = 'OUT_OF_NETWORK',
    COULD_NOT_CALCULATE = 'COULD_NOT_CALCULATE',
    NO_STATE = 'NO_STATE',
    OUT_OF_STATE = 'OUT_OF_STATE'
}


export const UserPriceEstimateErrorStateMetadata: EnumMetadata<UserPriceEstimateErrorState> = {
    name: 'UserPriceEstimateErrorState',
    values: {
            INACTIVE_COVERAGE: 'INACTIVE_COVERAGE',
            SELF_ADMINISTERED: 'SELF_ADMINISTERED',
            OUT_OF_NETWORK: 'OUT_OF_NETWORK',
            COULD_NOT_CALCULATE: 'COULD_NOT_CALCULATE',
            NO_STATE: 'NO_STATE',
            OUT_OF_STATE: 'OUT_OF_STATE'
    }
}
