import { css } from '@emotion/react';
import Grid from '@mui/material/Grid';
import React from 'react';

import { UserRead } from '@headway/api/models/UserRead';

import { theme } from './theme';

export interface BenefitsGridProps {
  user: UserRead;
  onUpdateInsuranceClick?: () => void;
}

/** Displays the user's current insurance details in a 2x2 grid. */
export const BenefitsGrid: React.FC<
  React.PropsWithChildren<BenefitsGridProps>
> = ({ user, onUpdateInsuranceClick }) => {
  return (
    <div css={benefitsGridCss}>
      <Grid container>
        <Grid item xs={6} css={benefitItemCss}>
          <div css={{ fontWeight: theme.fontWeight.bold }}>
            Insurance Carrier
          </div>{' '}
          <div>{user.activeUserInsurance?.frontEndCarrierName}</div>
        </Grid>
        <Grid item xs={6} css={benefitItemCss}>
          <div css={{ fontWeight: theme.fontWeight.bold }}>Member ID</div>{' '}
          <div>{user.activeUserInsurance?.memberId}</div>
        </Grid>
        <Grid item xs={6} css={benefitItemCss}>
          <div css={{ fontWeight: theme.fontWeight.bold }}>Member Name</div>{' '}
          <div>
            {user.activeUserInsurance?.firstName}{' '}
            {user.activeUserInsurance?.lastName}
          </div>
        </Grid>
        <Grid item xs={6} css={benefitItemCss}>
          <div css={{ fontWeight: theme.fontWeight.bold }}>Group Number</div>{' '}
          <div>{user.activeUserInsurance?.groupNumber || '-'}</div>
        </Grid>
        <Grid item xs={6} css={benefitItemCss}>
          <div css={{ fontWeight: theme.fontWeight.bold }}>Date of Birth</div>{' '}
          <div>{user.activeUserInsurance?.dob}</div>
        </Grid>
        {!!onUpdateInsuranceClick && (
          <Grid item>
            <span css={{ fontSize: theme.fontSize.sm }}>
              Wrong information?{' '}
              <span
                css={{
                  textDecoration: 'underline',
                  '&:hover': {
                    cursor: 'pointer',
                  },
                }}
                onClick={onUpdateInsuranceClick}
              >
                Update insurance
              </span>
            </span>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

/* styles */
const benefitsGridCss = css`
  display: flex;
  background: ${theme.color.background};
  color: ${theme.color.black};
  border: 1px solid ${theme.color.border};
  border-radius: 6px;
  padding: ${theme.space.xl};
  margin-bottom: ${theme.space.base};
`;

const benefitItemCss = css`
  padding-bottom: ${theme.space.xs};
`;
