import React from 'react';

import { FrontEndCarrierIdentifier } from '@headway/api/models/FrontEndCarrierIdentifier';
import { LookupStatus } from '@headway/api/models/LookupStatus';
import { UserInsuranceRead } from '@headway/api/models/UserInsuranceRead';
import { Button } from '@headway/helix/Button';
import { ContentText } from '@headway/helix/ContentText';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { Link } from '@headway/helix/Link';
import { NO_DATA_FLAT_FEE } from '@headway/shared/constants/userInsuranceOutage';
import { trackEvent, trackPageView } from '@headway/shared/utils/analytics';
import { isFrontEndCarrierIdBcbs } from '@headway/shared/utils/insuranceUtils';

export const insuranceShouldBlockTicketCreation = (
  insurance: UserInsuranceRead | undefined
) => {
  // TODO THIS IS A TEMPORARY CONDITION ADDED DURING BSC REMEDIATION
  // CONTACT SHIKHA FOR MORE INFO
  return false;
  if (!insurance) {
    return false;
  }
  return isMagellanInsurance(insurance);
};

export const InsuranceIsMedicareOrMedicaidAlert: React.FC<
  React.PropsWithChildren<ContactFormInsuranceAlertsProps>
> = ({
  insurance,
  contactUs = false,
  isProvider = false,
  baseUrl,
  ...rest
}) => {
  return (
    <GuidanceCard layout="vertical" variant="warning" {...rest}>
      <ContentText>
        Looks like {isProvider ? 'your client’s' : 'your'} plan is through
        Medicare / Medicaid. We don’t support this plan at this time, even those
        administered through{' '}
        {insurance?.frontEndCarrierName ||
          `${isProvider ? "your client's" : 'your'} plan`}
        .
      </ContentText>
      {contactUs && (
        <ContentText>
          {' '}
          Not insured through Medicare or Medicaid?&nbsp;
          <Link href={`${baseUrl}/contact`} target="_blank" rel="noreferrer">
            Contact us.
          </Link>
        </ContentText>
      )}
    </GuidanceCard>
  );
};

export const InsuranceIsInactiveAlert: React.FC<
  React.PropsWithChildren<ContactFormInsuranceAlertsProps>
> = ({
  contactUs,
  insuranceLocation = 'above',
  baseUrl,
  onOpenInsuranceModal,
  isSiteMessagingOppsEnabled = false,
  ...rest
}) => {
  if (isSiteMessagingOppsEnabled) {
    return (
      <GuidanceCard variant="warning">
        <ContentText>
          Your plan is showing as "inactive". This could be a temporary problem
          with the info we received from your insurer. If you have new insurance
          details,{' '}
          <Button variant="link" onPress={onOpenInsuranceModal}>
            add them here
          </Button>
          .
          {contactUs && (
            <>
              {' '}
              If you believe this plan is active,{' '}
              <Link href={`${baseUrl}/contact`} target="_blank">
                request a manual verification
              </Link>{' '}
              and we’ll get back to you.
            </>
          )}
        </ContentText>
      </GuidanceCard>
    );
  }

  return (
    <GuidanceCard layout="vertical" variant="warning" {...rest}>
      <ContentText>
        It looks like this plan is no longer active. If there are new insurance
        details,&nbsp;
        <Button variant="link" size="medium" onPress={onOpenInsuranceModal}>
          add them here
        </Button>
        &nbsp;to instantly verify coverage.
        {contactUs && (
          <>
            {' '}
            If this sounds incorrect and you believe the plan is still
            active,&nbsp;
            <Link href={`${baseUrl}/contact`} target="_blank" rel="noreferrer">
              contact us
            </Link>
            &nbsp;to report an insurance issue and we’ll get back to you.
          </>
        )}
      </ContentText>
    </GuidanceCard>
  );
};

const NotCoveredAlert = ({
  onClickOpenInsuranceIneligibilityExplainerModal,
  isProvider,
}: {
  onClickOpenInsuranceIneligibilityExplainerModal?: () => void;
  isProvider?: boolean;
}) => {
  return (
    <GuidanceCard layout="vertical" variant="error">
      <ContentText>
        Based on the information we have about your {isProvider && `client’s`}{' '}
        insurance, {isProvider ? `they` : `you`} are not currently covered for
        sessions on our platform. For more details,{' '}
        {isProvider ? 'they' : 'you'} can navigate to{' '}
        {isProvider ? `their` : `your`}{' '}
        {isProvider ? (
          'benefits page'
        ) : (
          <Link href="/benefits">benefits page</Link>
        )}{' '}
        or call {isProvider ? `their` : `your`} insurance company and reach out
        to our team once you have a reference number.
      </ContentText>
    </GuidanceCard>
  );
};

const OldDataOutageAlert = ({
  insuranceName,
  hasPresumptiveAccumulators,
  isProvider,
}: {
  insuranceName?: string;
  hasPresumptiveAccumulators: boolean;
  isProvider?: boolean;
}) => {
  if (hasPresumptiveAccumulators) {
    return (
      <GuidanceCard layout="vertical" variant="warning">
        <ContentText>
          {insuranceName
            ? insuranceName
            : isProvider
              ? `Your client's insurer`
              : `Your insurer`}
          ’s system is currently down, but {isProvider ? 'your client' : 'you'}{' '}
          can keep attending sessions.{' '}
          {isProvider ? `Your client will` : `You'll`}
          pay full in-network cost (due to {isProvider ? `their` : `your`}{' '}
          deductible or out-of-pocket max likely resetting) with a refund later
          if{' '}
          {insuranceName
            ? insuranceName
            : isProvider
              ? `your client's insurer`
              : `your insurer`}{' '}
          confirms the final price is lower.
        </ContentText>
      </GuidanceCard>
    );
  } else {
    return (
      <GuidanceCard layout="vertical" variant="warning">
        <ContentText>
          {insuranceName
            ? insuranceName
            : isProvider
              ? `Your client's insurer`
              : `Your insurer`}
          ’s system is currently down, but {isProvider ? 'your client' : 'you'}{' '}
          can keep attending sessions. We’ll charge{' '}
          {isProvider ? 'them' : 'you'} the same cost{' '}
          {isProvider ? `they've` : `you've`} been paying recently, with a
          refund or additional charge later if necessary. We’re checking in with{' '}
          {insuranceName
            ? insuranceName
            : isProvider
              ? `your client's insurer`
              : `your insurer`}{' '}
          daily and will let you know as soon as we have an update.
        </ContentText>
      </GuidanceCard>
    );
  }
};

const NoDataOutageAlert = ({
  insuranceName,
  useFullCost,
  isProvider,
}: {
  insuranceName?: string;
  useFullCost: boolean;
  isProvider?: boolean;
}) => {
  return (
    <GuidanceCard layout="vertical" variant="warning">
      <ContentText>
        {insuranceName
          ? insuranceName
          : isProvider
            ? `Your client's insurer`
            : `Your insurer`}
        ’s system is currently down, but {isProvider ? 'your client' : 'you'}{' '}
        can keep attending sessions. We’ll charge {isProvider ? 'them' : 'you'}{' '}
        {useFullCost
          ? `the full cost of the session for now`
          : `a temporary flat rate of $${NO_DATA_FLAT_FEE}`}
        , with a refund or corrected bill later if necessary. We’re checking in
        with{' '}
        {insuranceName
          ? insuranceName
          : isProvider
            ? `your client's insurer`
            : `your insurer`}{' '}
        daily and will let you know as soon as we have an update.
      </ContentText>
    </GuidanceCard>
  );
};

const TerminatedAlert = ({
  onClickOpenInsuranceIneligibilityExplainerModal,
}: {
  onClickOpenInsuranceIneligibilityExplainerModal?: () => void;
}) => {
  return (
    <GuidanceCard variant="error">
      <ContentText>This plan is not active.</ContentText>
      {onClickOpenInsuranceIneligibilityExplainerModal && (
        <Button
          variant="link"
          onPress={() => {
            onClickOpenInsuranceIneligibilityExplainerModal();
          }}
        >
          Learn more
        </Button>
      )}
    </GuidanceCard>
  );
};

const NoMentalHealthBenefitsAlert = ({
  onClickOpenInsuranceIneligibilityExplainerModal,
}: {
  onClickOpenInsuranceIneligibilityExplainerModal?: () => void;
}) => {
  return (
    <GuidanceCard variant="error">
      <ContentText>
        This plan does not include mental health coverage.
      </ContentText>
      {onClickOpenInsuranceIneligibilityExplainerModal && (
        <Button
          variant="link"
          onPress={() => {
            onClickOpenInsuranceIneligibilityExplainerModal();
          }}
        >
          Learn more
        </Button>
      )}
    </GuidanceCard>
  );
};

const ManualVerificationRequiredAlert = ({
  isProvider,
  onClickOpenInsuranceIneligibilityExplainerModal,
}: {
  isProvider?: boolean;
  onClickOpenInsuranceIneligibilityExplainerModal?: () => void;
}) => {
  return (
    <GuidanceCard variant="error">
      <ContentText>
        {!!isProvider ? `Your client's` : 'Your'} plan needs to be manually
        verified. Please double check that{' '}
        {!!isProvider ? `your client's` : 'your'} insurance details match{' '}
        {!!isProvider ? `their` : 'your'} insurance card exactly before
        proceeding with a manual verification request.
      </ContentText>
      {onClickOpenInsuranceIneligibilityExplainerModal && (
        <Button
          variant="link"
          onPress={() => {
            onClickOpenInsuranceIneligibilityExplainerModal();
          }}
        >
          Learn more
        </Button>
      )}
    </GuidanceCard>
  );
};

export const InsuranceIsMagellanAlert: React.FC<
  React.PropsWithChildren<ContactFormInsuranceAlertsProps>
> = ({
  insurance,
  contactUs = false,
  isProvider = false,
  baseUrl,
  ...rest
}) => {
  return (
    <GuidanceCard layout="vertical" variant="warning" {...rest}>
      {insurance?.frontEndCarrierName ? (
        <ContentText>
          We detected that {isProvider ? 'your client is' : 'you’re'} on a{' '}
          <b>{insurance.frontEndCarrierName}</b> plan with{' '}
          <b>Behavioral Health benefits</b> through <b>Magellan</b>. Your
          insurance will be inactive at this time since Headway does not offer
          coverage to this specific plan.
        </ContentText>
      ) : (
        <ContentText>
          We detected that {isProvider ? "your client's" : 'your'} plan has{' '}
          <b>Behavioral Health benefits</b> through <b>Magellan</b>. Your
          insurance will be inactive at this time since Headway does not offer
          coverage to this specific plan.
        </ContentText>
      )}
      {contactUs && (
        <ContentText>
          {' '}
          Not insured through Magellan?&nbsp;
          <Link href={`${baseUrl}/contact`} target="_blank" rel="noreferrer">
            Contact us
          </Link>
        </ContentText>
      )}
    </GuidanceCard>
  );
};

export const InsuranceIsInactiveBCBSAlert: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  return (
    <GuidanceCard layout="vertical" variant="warning">
      <ContentText>
        Looks like your Blue Cross Blue Shield insurance details are showing as
        ineligible.
      </ContentText>
      <br />
      <br />
      <ContentText>
        If you are a BlueCard member, please make sure to include the full
        alphanumeric identification number, including the first three letters,
        for example “XYZ123456789”
      </ContentText>
    </GuidanceCard>
  );
};

export const InsuranceInformationIncorrect: React.FC<
  React.PropsWithChildren<ContactFormInsuranceAlertsProps>
> = ({
  contactUs = false,
  baseUrl,
  isHumanErrorBannerEnabled = false,
  insurance,
  isProvider,
  providerUserId,
  ...rest
}) => {
  const { onOpenInsuranceModal, isHwInputError } = rest;

  React.useEffect(() => {
    if (isHumanErrorBannerEnabled) {
      trackPageView({
        name: 'Inactive Insurance Messaging Viewed',
        properties: {
          lookupStatus: LookupStatus.INSUFFICIENT_OR_INCORRECT_INFORMATION,
          userId:
            isProvider && providerUserId ? providerUserId : insurance!.userId,
          patientUserId: isProvider ? insurance!.userId : null,
          confettiHumanInputErrorExperimentGroup: 'treatment',
          confettiHumanInputErrorSurfacedBy: isHwInputError ? 'HW' : 'CHC',
        },
      });
    }
  }, [isHumanErrorBannerEnabled]);

  // TODO(soleil/sc-128914): cleanup during flag cleanup
  if (isHumanErrorBannerEnabled) {
    return (
      <GuidanceCard variant="error" layout="vertical">
        <ContentText>
          <strong>Did you type that correctly?</strong> Please check for typos
          and confirm the details match the details on the insurance card for
          the client (the person receiving care). If everything matches,{' '}
          <Link
            href={`${baseUrl}/contact`}
            target="_blank"
            onClick={() => {
              trackEvent({
                name: 'Contact Form Link Clicked',
                properties: {
                  lookupStatus:
                    LookupStatus.INSUFFICIENT_OR_INCORRECT_INFORMATION,
                  userId: insurance!.userId,
                  confettiHumanInputErrorExperimentGroup: 'treatment',
                  confettiHumanInputErrorSurfacedBy: isHwInputError
                    ? 'HW'
                    : 'CHC',
                },
              });
            }}
          >
            request a manual verification
          </Link>
          .
        </ContentText>
        {onOpenInsuranceModal ? (
          <Button
            variant="link"
            onPress={() => {
              trackEvent({
                name: 'Inactive Insurance Messaging CTA Clicked',
                properties: {
                  lookupStatus:
                    LookupStatus.INSUFFICIENT_OR_INCORRECT_INFORMATION,
                  userId: providerUserId!,
                  patientUserId: insurance!.userId,
                  confettiHumanInputErrorExperimentGroup: 'treatment',
                  confettiHumanInputErrorSurfacedBy: isHwInputError
                    ? 'HW'
                    : 'CHC',
                },
              });
              onOpenInsuranceModal();
            }}
          >
            Update insurance information
          </Button>
        ) : null}
      </GuidanceCard>
    );
  }
  return (
    <GuidanceCard layout="vertical" variant="warning">
      <ContentText>
        We had trouble finding a patient from the provided information
      </ContentText>
      <br />
      <br />
      <ContentText>
        Please double check that the information provided matches what the
        insurer has on file for that patient.
      </ContentText>
      {contactUs && (
        <ContentText>
          {' '}
          Patient information looks right to you?&nbsp;
          <Link href={`${baseUrl}/contact`} target="_blank" rel="noreferrer">
            Contact us
          </Link>
        </ContentText>
      )}
    </GuidanceCard>
  );
};

interface ContactFormInsuranceAlertsProps {
  insurance?: UserInsuranceRead;
  contactUs?: boolean;
  isProvider?: boolean;
  providerUserId?: number;
  providerId?: number;
  baseUrl: string;
  insuranceLocation?: 'above' | 'below';

  onOpenInsuranceModal?: () => void;
  onClickOpenInsuranceIneligibilityExplainerModal?: () => void;
  isOONBannerEnabled?: boolean;
  isHumanErrorBannerEnabled?: boolean;
  isSiteMessagingOppsEnabled?: boolean;
  isHwInputError?: boolean;
  isPriceDisputeFeaturesEnabled?: boolean;
}

const isMagellanInsurance = (insurance?: UserInsuranceRead) =>
  insurance?.frontEndCarrierId === FrontEndCarrierIdentifier.MAGELLAN ||
  insurance?.latestEligibilityLookup?.frontEndCarrierId ===
    FrontEndCarrierIdentifier.MAGELLAN;
const isInactiveInsurance = (insurance: UserInsuranceRead) =>
  !insurance.latestEligibilityLookup?.isInsuranceActive &&
  !insurance.isInNoDataOutage;

const isOONForHeadway = (insurance: UserInsuranceRead) =>
  insurance.latestEligibilityLookup?.outOfNetwork;

const isTerminated = (insurance: UserInsuranceRead) =>
  insurance.latestEligibilityLookup?.terminated;

const isNoMentalHealthBenefits = (insurance: UserInsuranceRead) =>
  insurance.latestEligibilityLookup?.hasNoMentalHealthBenefits;

const isManualVerificationRequired = (insurance: UserInsuranceRead) =>
  insurance.latestEligibilityLookup?.manualVerificationRequired;

const isMedicareOrMedicaidInsurance = (insurance: UserInsuranceRead) =>
  insurance.isMedicaid || insurance.latestEligibilityLookup?.isMedicare;

const isInOldDataOutage = (insurance: UserInsuranceRead) =>
  insurance.isInOldDataOutage;

const isInNoDataFullCostOutage = (insurance: UserInsuranceRead) =>
  insurance.isInNoDataOutage;

const isInNoDataFlatFeeOutage = (insurance: UserInsuranceRead) =>
  insurance.isInNoDataFlatFeeOutage;

export const ContactFormInsuranceAlerts: React.FC<
  React.PropsWithChildren<ContactFormInsuranceAlertsProps>
> = ({
  isHumanErrorBannerEnabled = false,
  isSiteMessagingOppsEnabled = false,
  isPriceDisputeFeaturesEnabled = false,
  ...rest
}) => {
  const { insurance, isProvider, providerId, isHwInputError } = rest;
  if (!insurance) {
    return <></>;
  }

  if (isMagellanInsurance(insurance)) {
    return <InsuranceIsMagellanAlert {...rest} />;
  }

  if (isMedicareOrMedicaidInsurance(insurance)) {
    return <InsuranceIsMedicareOrMedicaidAlert {...rest} />;
  }

  if (
    !isProvider &&
    isFrontEndCarrierIdBcbs(insurance.frontEndCarrierId) && //TODO SC-263184
    isInactiveInsurance(insurance)
  ) {
    return <InsuranceIsInactiveBCBSAlert />;
  }

  if (
    isPriceDisputeFeaturesEnabled &&
    (isOONForHeadway(insurance) ||
      // there are provider-specific banners for these two latter cases
      (!isProvider &&
        (isTerminated(insurance) || isNoMentalHealthBenefits(insurance))))
  ) {
    return <NotCoveredAlert isProvider={isProvider} {...rest} />;
  }

  if (
    insurance.latestEligibilityLookup?.lookupStatus ===
      LookupStatus.INSUFFICIENT_OR_INCORRECT_INFORMATION ||
    isHumanErrorBannerEnabled
  ) {
    return (
      <InsuranceInformationIncorrect
        isHumanErrorBannerEnabled={isHumanErrorBannerEnabled}
        insurance={insurance}
        isHwInputError={isHwInputError}
        {...rest}
      />
    );
  }
  if (isProvider && providerId && isTerminated(insurance)) {
    return <TerminatedAlert {...rest} />;
  }
  if (isProvider && providerId && isNoMentalHealthBenefits(insurance)) {
    return <NoMentalHealthBenefitsAlert {...rest} />;
  }

  if (isManualVerificationRequired(insurance)) {
    return (
      <ManualVerificationRequiredAlert isProvider={isProvider} {...rest} />
    );
  }

  if (isInOldDataOutage(insurance) && isPriceDisputeFeaturesEnabled) {
    const hasPresumptiveAccumulators =
      !!insurance.latestOutage?.accumulatorsPresumedReset?.length;
    return (
      <OldDataOutageAlert
        insuranceName={insurance.frontEndCarrierName}
        hasPresumptiveAccumulators={hasPresumptiveAccumulators}
        isProvider={isProvider}
      />
    );
  }

  if (
    isPriceDisputeFeaturesEnabled &&
    (isInNoDataFullCostOutage(insurance) || isInNoDataFlatFeeOutage(insurance))
  ) {
    return (
      <NoDataOutageAlert
        insuranceName={insurance.frontEndCarrierName}
        useFullCost={insurance.isInOldDataOutage}
        isProvider={isProvider}
      />
    );
  }

  if (isInactiveInsurance(insurance)) {
    return (
      <InsuranceIsInactiveAlert
        {...rest}
        isSiteMessagingOppsEnabled={isSiteMessagingOppsEnabled}
      />
    );
  }

  return <></>;
};
