import { FrontEndCarrierRead } from '@headway/api/models/FrontEndCarrierRead';
import { MarketRead } from '@headway/api/models/MarketRead';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { UserAppointmentReadiness } from '@headway/api/models/UserAppointmentReadiness';
import { UserInsuranceDetailsState } from '@headway/api/models/UserInsuranceDetailsState';
import { UserInsuranceRead } from '@headway/api/models/UserInsuranceRead';
import { UserPriceEstimateErrorState } from '@headway/api/models/UserPriceEstimateErrorState';
import { UserPriceEstimateResponse } from '@headway/api/models/UserPriceEstimateResponse';
import { isCarrierInHeadwayNetworkForState } from '@headway/shared/utils/carriers';
import { hasOutOfNetworkReadinessIssue } from '@headway/shared/utils/insuranceUtils';

import PricingCardStatus from './PricingCardStatus';

export const getPricingCardStatusFromPricingDetails = (
  pricingDetails: UserPriceEstimateResponse,
  isPrescriber: boolean = false,
  liveMarkets: MarketRead[],
  userState?: UnitedStates,
  userFrontendCarrier?: FrontEndCarrierRead,
  userInsurance?: UserInsuranceRead,
  appointmentReadiness?: UserAppointmentReadiness
): PricingCardStatus => {
  const { errorState } = pricingDetails;

  if (errorState === UserPriceEstimateErrorState.OUT_OF_NETWORK) {
    // if patient's state is live & the frontend carrier has that state
    // CTA to redirect to the corresponding search page
    // ONLY if the lookup does not have the OON bit set, in which case the patient's plan is just totally OON w/r/t Headway
    if (
      userState &&
      userFrontendCarrier &&
      userInsurance &&
      liveMarkets.find((market) => market.state === userState) &&
      isCarrierInHeadwayNetworkForState(userFrontendCarrier, userState) &&
      !hasOutOfNetworkReadinessIssue(appointmentReadiness?.insurance)
    ) {
      return PricingCardStatus.ERROR_OUT_OF_NETWORK_REDIRECT;
    }
    return PricingCardStatus.ERROR_OUT_OF_NETWORK;
  }

  // Carrier matches. check state before insurance detail info
  if (errorState === UserPriceEstimateErrorState.OUT_OF_STATE) {
    if (
      userState &&
      userFrontendCarrier &&
      userInsurance &&
      liveMarkets.find((market) => market.state === userState) &&
      isCarrierInHeadwayNetworkForState(userFrontendCarrier, userState)
    ) {
      return PricingCardStatus.ERROR_OTHER_STATE_REDIRECT;
    }
    return PricingCardStatus.ERROR_OUT_OF_STATE;
  }
  if (errorState === UserPriceEstimateErrorState.NO_STATE) {
    return PricingCardStatus.NO_STATE;
  }

  // now state matches and carrier matches.
  // if partial information, we want to collect insr details
  if (
    pricingDetails.insuranceDetailsState === UserInsuranceDetailsState.PARTIAL
  ) {
    return PricingCardStatus.PARTIAL_INFORMATION;
  }

  if (
    errorState === UserPriceEstimateErrorState.SELF_ADMINISTERED ||
    errorState === UserPriceEstimateErrorState.INACTIVE_COVERAGE ||
    errorState === UserPriceEstimateErrorState.COULD_NOT_CALCULATE
  ) {
    return PricingCardStatus.ERROR_IN_NETWORK;
  }

  if (
    pricingDetails.insuranceDetailsState ===
    UserInsuranceDetailsState.NOT_ON_FILE
  ) {
    return PricingCardStatus.EMPTY_INFORMATION;
  }

  // if we get here, insurance details are fully on file and are in state.

  const isThereARangeInPrices =
    pricingDetails.firstSessionMinPrice !==
      pricingDetails.firstSessionMaxPrice ||
    pricingDetails.otherSessionsMinPrice !==
      pricingDetails.otherSessionsMaxPrice;
  if (
    !isThereARangeInPrices &&
    pricingDetails.firstSessionMinPrice === 0 &&
    pricingDetails.otherSessionsMinPrice === 0
  ) {
    return PricingCardStatus.ZERO_DOLLAR_PRICE;
  }

  if (
    !isThereARangeInPrices &&
    pricingDetails.firstSessionMinPrice === pricingDetails.otherSessionsMinPrice
  ) {
    return PricingCardStatus.COPAY_DUE;
  }

  if (!isThereARangeInPrices) {
    return PricingCardStatus.DEDUCTIBLE_REMAINING;
  }

  return isPrescriber
    ? PricingCardStatus.DEDUCTIBLE_REMAINING_PRESCRIBER
    : PricingCardStatus.DEDUCTIBLE_REMAINING_RANGE;
};
