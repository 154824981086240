import { css } from '@emotion/react';
import Skeleton from '@mui/material/Skeleton';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Formik } from 'formik';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';
import moment from 'moment';
import 'moment-timezone';
import { useSearchParams } from 'next/navigation';
import React, { useState } from 'react';
import * as Yup from 'yup';

import { AvailabilityEventResponse } from '@headway/api/models/AvailabilityEventResponse';
import { ProviderAddressRead } from '@headway/api/models/ProviderAddressRead';
import { ProviderAppointmentStatus } from '@headway/api/models/ProviderAppointmentStatus';
import { ProviderEventChannel } from '@headway/api/models/ProviderEventChannel';
import { ProviderEventType } from '@headway/api/models/ProviderEventType';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { UserClaimReadinessCheck } from '@headway/api/models/UserClaimReadinessCheck';
import { UserFreezeReason } from '@headway/api/models/UserFreezeReason';
import { UserRead } from '@headway/api/models/UserRead';
import { UserSessionLocationPreference } from '@headway/api/models/UserSessionLocationPreference';
import { UserApi } from '@headway/api/resources/UserApi';
import { Button } from '@headway/helix/Button';
import { LinkButton } from '@headway/helix/LinkButton';
import {
  InPerson as InPersonIcon,
  VideoChat as VideoChatIcon,
} from '@headway/icons/dist/provider';
import BookingAvailabilitySelector, {
  AVAILABILITY_BLOCK_MIN_WIDTH_PIXELS,
} from '@headway/shared/components/BookingAvailabilitySelector';
import { useProviderAvailability } from '@headway/shared/hooks/useProviderAvailability';
import { useActiveUserFreezes } from '@headway/shared/hooks/useUserFreezes';
import { useQuery } from '@headway/shared/react-query';
import { BookingAvailabilityEventSelection } from '@headway/shared/types/booking';
import { trackEvent } from '@headway/shared/utils/analytics';
import { getErrorMessage } from '@headway/shared/utils/error';
import { getProviderDisplayFirstAndLastWithPrenomial } from '@headway/shared/utils/providers';
import { isUserRegistered } from '@headway/shared/utils/userVerificationStatus';
import { Radio } from '@headway/ui';
import { FieldControl } from '@headway/ui/form/FieldControl';
import { FieldRadioGroup } from '@headway/ui/form/FieldRadioGroup';
import { SafeFormikForm } from '@headway/ui/form/SafeFormikForm';
import { theme } from '@headway/ui/theme';

import { getProvidersSearchPath } from '../../../hooks/useProvidersSearch';
import { VERIFY_CLAIM_READINESS_QUERY_KEY } from '../../../utils/cacheKeys';
import {
  CoordinationOfBenefitsFreezeMessage,
  OtherFreezeMessage,
  OutOfNetworkFreezeMessage,
} from '../../FreezeMessage';
import { trackBookAppointmentButtonClicked } from '../ProfilePage/analyticsEvents';

const DEFAULT_DAYS_PER_PAGE = 3;

interface BookingAvailabilityProps {
  addresses: ProviderAddressRead[];
  comingFromReferralQuestionnaire?: boolean;
  daysPerPage?: number;
  isMobileBookingPage?: boolean;
  goBackInTheReferralFlow?: () => void;
  onAvailabilitySlotClick: (autoSelected: boolean) => void;
  onError: (err: string) => void;
  // TODO update type to ProviderEventCreate once moved over to FastAPI
  onNext: (
    appointmentEvent: BookingAvailabilityEventSelection,
    sessionId?: number
  ) => void;
  provider: ProviderRead;
  user: UserRead;
  descriptionText?: React.ReactNode;
  hideIntakeDescription?: boolean;
  sessionId?: number;
  mustBeVirtual?: boolean;
}

const BookingAvailability = ({
  provider,
  user,
  addresses,
  daysPerPage = DEFAULT_DAYS_PER_PAGE,
  onAvailabilitySlotClick,
  onError,
  onNext,
  goBackInTheReferralFlow,
  comingFromReferralQuestionnaire = false,
  descriptionText,
  hideIntakeDescription,
  isMobileBookingPage = false,
  sessionId,
  mustBeVirtual,
}: BookingAvailabilityProps) => {
  const [selectedAvailability, setSelectedAvailability] =
    useState<AvailabilityEventResponse>();

  const searchParams = useSearchParams();
  const requiresIntake = provider.requiresIntakeCall || false;
  const providerAddressesByIds = keyBy(addresses, (address) => address.id);

  const onAvailabilitySelect = ({
    availability,
    autoSelected = false,
  }: {
    availability: AvailabilityEventResponse;
    autoSelected?: boolean;
  }) => {
    onAvailabilitySlotClick(autoSelected);

    setSelectedAvailability(availability);
  };

  const { data: userFreezeReasons = [] } = useActiveUserFreezes(user.id);

  const hasCobFreeze = userFreezeReasons.includes(
    UserFreezeReason.COB_NEEDS_PAYER_CONFIRMATION
  );
  const hasOonFreeze = userFreezeReasons.includes(UserFreezeReason.OON_PLAN);
  const hasOtherFreeze =
    userFreezeReasons.includes(UserFreezeReason.OTHER) ||
    userFreezeReasons.includes(UserFreezeReason.PATIENT_ELIGIBILITY_NOT_FOUND);

  const [patientIsOONForSelectedAddress, setPatientIsOONForSelectedAddress] =
    useState<boolean>(false);
  const claimReadinessQuery = useQuery(
    [
      VERIFY_CLAIM_READINESS_QUERY_KEY,
      user.activeUserInsuranceId,
      selectedAvailability?.providerAddressId,
    ],
    () => {
      if (!user.activeUserInsuranceId) {
        return null;
      }
      return UserApi.getClaimReadiness(user.id, {
        provider_address_id: selectedAvailability?.providerAddressId,
      });
    },
    {
      onSuccess: (data) => {
        if (data?.ok) {
          setPatientIsOONForSelectedAddress(false);
          return;
        }
        setPatientIsOONForSelectedAddress(
          data?.requirements?.includes(
            UserClaimReadinessCheck.OON_FOR_PROVIDER_ADDRESS
          ) ?? true
        );
      },
    }
  );

  const providerAvailabilityQuery = useProviderAvailability(provider, {
    select: (allAvailabilities): Array<AvailabilityEventResponse> => {
      if (!mustBeVirtual) {
        return allAvailabilities;
      }

      const telehealthAvailabilities = [];
      for (const availability of allAvailabilities) {
        if (!availability.telehealth) {
          continue;
        }

        telehealthAvailabilities.push({
          ...availability,
          providerAddressId: undefined,
        });
      }

      return telehealthAvailabilities;
    },
    onSuccess: (data: AvailabilityEventResponse[]) => {
      if (!data || data.length === 0) {
        trackEvent({
          name: 'Provider Availabilities Viewed',
          properties: {
            providerId: provider.id,
          },
        });
        return;
      }

      const defaultAvailability = data[0];
      if (requiresIntake) {
        onAvailabilitySelect({
          availability: defaultAvailability,
          autoSelected: true,
        });
      } else if (
        user.sessionLocationPreference ===
        UserSessionLocationPreference.IN_PERSON
      ) {
        const selected =
          data.find((availability) => !!availability.providerAddressId) ||
          defaultAvailability;
        onAvailabilitySelect({
          availability: selected,
          autoSelected: true,
        });
      } else if (
        user.sessionLocationPreference ===
          UserSessionLocationPreference.VIRTUAL ||
        user.sessionLocationPreference ===
          UserSessionLocationPreference.VIRTUAL_FOR_NOW
      ) {
        const selected =
          data.find((availability) => availability.telehealth) ||
          defaultAvailability;
        onAvailabilitySelect({
          availability: selected,
          autoSelected: true,
        });
      } else {
        onAvailabilitySelect({
          availability: defaultAvailability,
          autoSelected: true,
        });
      }
      trackEvent({
        name: 'Provider Availabilities Viewed',
        properties: {
          providerId: provider.id,
        },
      });
    },
    onError: (err: unknown) => {
      const defaultMessage = `We couldn't determine ${getProviderDisplayFirstAndLastWithPrenomial(
        provider
      )}'s availability. Please refresh the page to try again.`;
      const message = getErrorMessage(err, defaultMessage);
      onError(message);
    },
    staleTime: Infinity,
  });

  const providerAvailabilities = providerAvailabilityQuery.data;

  const muiTheme = useTheme();
  const verySmallScreen = useMediaQuery(
    muiTheme.breakpoints.down(AVAILABILITY_BLOCK_MIN_WIDTH_PIXELS * 3.5)
  );
  const daysToShow = verySmallScreen ? 1 : daysPerPage;

  const isAmbiguousBookingLocation =
    !!selectedAvailability &&
    isAmbiguousBookingLocationEvent(selectedAvailability, mustBeVirtual);

  if (providerAvailabilityQuery.isLoading) {
    return <BookingAvailabilityLoading />;
  }

  if (providerAvailabilityQuery.isError) {
    return (
      <div css={errorCss}>
        <div css={errorTextCss}>
          We couldn't determine{' '}
          {getProviderDisplayFirstAndLastWithPrenomial(provider)}
          's availability. Please refresh the page to try again.
        </div>
      </div>
    );
  }

  if (isEmpty(providerAvailabilities)) {
    return (
      <div css={errorCss}>
        <div css={errorTextCss}>No availability in the next 2 weeks</div>
        {comingFromReferralQuestionnaire ? (
          <Button
            variant="primary"
            size="medium"
            onPress={goBackInTheReferralFlow}
          >
            See other therapists
          </Button>
        ) : (
          <LinkButton variant="primary" href={getProvidersSearchPath()}>
            See other therapists
          </LinkButton>
        )}
      </div>
    );
  }

  const providerAddress = selectedAvailability?.providerAddressId
    ? providerAddressesByIds[selectedAvailability.providerAddressId]
    : undefined;

  const blockBooking = userFreezeReasons.length > 0;

  return (
    <Formik
      enableReinitialize
      validationSchema={Yup.object().shape({
        availability: Yup.object().required('Select an availability slot'),
        confirmedLocation: Yup.string().when('availability', {
          is: (availability: AvailabilityEventResponse) => {
            if (requiresIntake || !availability) {
              return false;
            }
            return isAmbiguousBookingLocationEvent(availability, mustBeVirtual);
          },
          then: Yup.string()
            .required('Confirm your session type')
            .test(
              'oonForLocation',
              'Your insurance is out of network for this office location. Please select a different location or schedule a telehealth session instead.',
              (val) => {
                return (
                  !isUserRegistered(user) ||
                  !user.activeUserInsurance ||
                  val === UserSessionLocationPreference.VIRTUAL ||
                  (!claimReadinessQuery.isLoading &&
                    !patientIsOONForSelectedAddress)
                );
              }
            ),
        }),
      })}
      initialValues={{
        availability: selectedAvailability,
        confirmedLocation: user.sessionLocationPreference
          ? [
              UserSessionLocationPreference.VIRTUAL,
              UserSessionLocationPreference.VIRTUAL_FOR_NOW,
            ].includes(user.sessionLocationPreference)
            ? UserSessionLocationPreference.VIRTUAL
            : user.sessionLocationPreference
          : undefined,
      }}
      onSubmit={async (values) => {
        if (!selectedAvailability) {
          return; // Shouldn't happen cuz we disable booking if no selection
        }

        trackBookAppointmentButtonClicked({
          availabilityEvent: selectedAvailability,
          algoliaQueryId: searchParams.get('queryId') || '',
        });

        const appointment: BookingAvailabilityEventSelection = {
          providerId: selectedAvailability.providerId,
          providerLicenseStateId: selectedAvailability.providerLicenseStateId,
          patientUserId: user.id,
          startDate: selectedAvailability.startDate,
          endDate: selectedAvailability.endDate,
          timeZone: selectedAvailability.timeZone,
          providerAddressId: undefined,
          telehealth: undefined,
          type: requiresIntake
            ? ProviderEventType.INTAKE_CALL
            : ProviderEventType.APPOINTMENT,
          channel: ProviderEventChannel.PATIENT_PORTAL,
          providerAppointment: {
            provider_id: selectedAvailability.providerId,
            user_id: user.id,
            status: ProviderAppointmentStatus.SCHEDULED,
          },
        };
        if (requiresIntake) {
          appointment.telehealth = false;
          appointment.providerAddressId = undefined;
        } else if (
          isAmbiguousBookingLocation &&
          values.confirmedLocation === UserSessionLocationPreference.IN_PERSON
        ) {
          appointment.providerAddressId =
            selectedAvailability.providerAddressId;
        } else if (
          isAmbiguousBookingLocation &&
          values.confirmedLocation === UserSessionLocationPreference.VIRTUAL
        ) {
          appointment.telehealth = selectedAvailability.telehealth;
        } else {
          appointment.telehealth = selectedAvailability.telehealth;
          appointment.providerAddressId =
            selectedAvailability.providerAddressId;
        }

        onNext(appointment, sessionId);
      }}
    >
      {({ isValid, setFieldValue, values, errors }) => {
        return (
          <div css={containerCss}>
            <SafeFormikForm>
              <p
                css={{
                  color: isMobileBookingPage
                    ? theme.color.black
                    : theme.color.textGray,
                  marginBottom: theme.space.xl,
                }}
              >
                {descriptionText}
                {descriptionText && ' '}
                {requiresIntake && !hideIntakeDescription ? (
                  <>
                    {getProviderDisplayFirstAndLastWithPrenomial(provider)}{' '}
                    prefers to have a quick <strong>phone consultation</strong>{' '}
                    before scheduling a session.{' '}
                  </>
                ) : null}
                All times are listed in your current timezone.
              </p>

              <BookingAvailabilitySelector
                daysPerPage={daysToShow}
                providerAvailabilities={providerAvailabilities ?? []}
                onAvailabilitySelect={({ availability, autoSelected }) => {
                  onAvailabilitySelect({ availability, autoSelected });
                  setFieldValue('availability', availability);
                }}
                requiresIntake={requiresIntake}
              />
              {!requiresIntake ? (
                <React.Fragment>
                  {selectedAvailability && isAmbiguousBookingLocation ? (
                    <FieldControl
                      aria-describedby="confirm-type-description"
                      name="confirmedLocation"
                      // @ts-expect-error
                      component="fieldset"
                    >
                      <legend css={formSectionTitleCss}>
                        How would you like to meet?
                      </legend>
                      <p
                        css={{
                          color: theme.color.textGray,
                        }}
                        id="confirm-type-description"
                      >
                        {provider.displayFirstName} offers in-person or virtual
                        options at this time.
                      </p>
                      <FieldRadioGroup name="confirmedLocation">
                        <RadioWithDescription
                          id="in-person"
                          label={
                            <div>
                              In person <InPersonIcon width={24} />
                            </div>
                          }
                          value={UserSessionLocationPreference.IN_PERSON}
                          description={
                            providerAddress
                              ? `${providerAddress.streetLine1}, ${providerAddress.city}, ${providerAddress.state}`
                              : 'In-person session'
                          }
                        />
                        <RadioWithDescription
                          id="virtual"
                          label={
                            <div>
                              Virtual visit <VideoChatIcon width={24} />
                            </div>
                          }
                          value={UserSessionLocationPreference.VIRTUAL}
                          description={`You will meet ${provider.displayFirstName} online over video`}
                        />
                      </FieldRadioGroup>
                    </FieldControl>
                  ) : selectedAvailability?.providerAddressId ? (
                    <output
                      css={{
                        display: 'block',
                        padding: theme.space.base,
                        borderRadius: 2,
                        border: `1px solid ${theme.color.lightGray}`,
                      }}
                    >
                      <div
                        css={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: theme.space.sm,
                          color: theme.color.black,
                        }}
                      >
                        <span>In person</span>
                        <InPersonIcon width={24} />
                      </div>
                      {providerAddress && (
                        <div css={{ color: theme.color.textGray }}>
                          <div>
                            {providerAddress.streetLine1}{' '}
                            {providerAddress.streetLine2}
                          </div>
                          <div>
                            {providerAddress.city}, {providerAddress.state}{' '}
                            {providerAddress.zipCode}
                          </div>
                        </div>
                      )}
                    </output>
                  ) : null}
                </React.Fragment>
              ) : null}

              <output
                css={{ color: theme.color.error, fontSize: theme.fontSize.sm }}
              >
                {errors && errors.confirmedLocation && (
                  <span>{errors.confirmedLocation}</span>
                )}
              </output>
              <div className="mt-4 flex flex-col">
                <Button
                  variant="primary"
                  size="large"
                  type="submit"
                  data-testid="bookingSlotContinue"
                  disabled={!selectedAvailability || !isValid || blockBooking}
                  data-dd-action-name="Book session Button"
                >
                  Book{requiresIntake ? ' phone call' : ' session'}
                  {selectedAvailability
                    ? ` for ${moment(selectedAvailability.startDate).format(
                        'MMM D'
                      )}`
                    : ''}
                </Button>
              </div>
            </SafeFormikForm>
            {hasCobFreeze && <CoordinationOfBenefitsFreezeMessage />}
            {hasOonFreeze && <OutOfNetworkFreezeMessage />}
            {hasOtherFreeze && <OtherFreezeMessage />}
          </div>
        );
      }}
    </Formik>
  );
};

const BookingAvailabilityLoading = () => (
  <div>
    <Skeleton height={theme.space.xl2} width="70%" />
    <Skeleton
      height="200px"
      variant="rectangular"
      width="100%"
      css={{ margin: `${theme.space.base} 0` }}
    />
    <Skeleton height={theme.space.xl2} width="100%" />
  </div>
);

function uuid() {
  return Date.now().toString(36) + Math.random().toString(36).substring(2);
}

interface RadioWithDescriptionProps extends React.ComponentProps<typeof Radio> {
  description: React.ReactNode;
}
const RadioWithDescription: React.FC<RadioWithDescriptionProps> = ({
  description,
  ...rest
}) => {
  const descriptionId = React.useRef(uuid());

  return (
    <div
      css={{
        padding: theme.space.base,
        // smaller bottom padding to account for the transform on .radio-description
        paddingBottom: theme.space.xs2,
        border: `1px solid ${theme.color.lightGray}`,
        position: 'relative',
        '&:not(:last-child)': {
          borderBottom: 'none',
        },
        '&:last-child': {
          borderBottomRightRadius: 4,
          borderBottomLeftRadius: 4,
        },
        '&:first-child': {
          borderTopRightRadius: 4,
          borderTopLeftRadius: 4,
        },
        '& .MuiFormControlLabel-label': {
          display: 'block',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          position: 'absolute',
          width: '100%',
          height: '100%',
          paddingTop: theme.space.base,
          // bit of a magic number, but its the outer box paddingLeft + radio control width + radio control padding right
          paddingLeft: 43,
          fontSize: theme.fontSize.base,
          color: theme.color.black,
          cursor: 'pointer',
          zIndex: 1,
        },
        '& .MuiFormControlLabel-label > div': {
          display: 'flex',
          alignItems: 'center',
          gap: theme.space.xs,
        },
        '& .radio-description': {
          marginLeft: 27, // control width + 1/2 control padding
          transform: `translateY(-${theme.space.sm})`,
        },
      }}
    >
      <Radio
        inputProps={{
          'aria-describedby': descriptionId.current,
        }}
        {...rest}
      />
      <p
        id={descriptionId.current}
        className="radio-description"
        css={{ margin: 0 }}
      >
        {description}
      </p>
    </div>
  );
};

function isAmbiguousBookingLocationEvent(
  event: AvailabilityEventResponse,
  mustBeVirtual?: boolean
) {
  if (mustBeVirtual) {
    return false;
  }
  return !!(event.providerAddressId && event.telehealth);
}

/* Styles */

const containerCss = css`
  font-size: ${theme.fontSize.base};
  position: relative;
`;

const errorCss = css`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${theme.space.lg} 0 ${theme.space.base} 0;
`;
const errorTextCss = css`
  color: ${theme.color.textGray};
  font-family: ${theme.fontFamily.postGrotesk};
  font-size: ${theme.fontSize.base};
  margin-bottom: ${theme.space.xs};
  text-align: center;
`;

const formSectionTitleCss = css`
  color: ${theme.color.black};
  font-family: ${theme.fontFamily.postGrotesk};
  font-size: ${theme.fontSize.xl};
  font-weight: ${theme.fontWeight.bold};
  margin-bottom: ${theme.space.xs};
  padding-top: ${theme.space.base};
  &.Mui-focused {
    color: ${theme.color.black};
  }
`;

const BookingAvailabilityWithProviders = (props: BookingAvailabilityProps) => {
  return <BookingAvailability {...props} />;
};

export {
  BookingAvailabilityWithProviders as BookingAvailability,
  BookingAvailabilityLoading,
};
