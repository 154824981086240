enum PricingCardStatus {
  COPAY_DUE = 1,
  ZERO_DOLLAR_PRICE = 2,
  DEDUCTIBLE_REMAINING = 3,
  DEDUCTIBLE_REMAINING_PRESCRIBER = 4,
  PARTIAL_INFORMATION = 5,
  EMPTY_INFORMATION = 6,
  ERROR_IN_NETWORK = 7,
  ERROR_OUT_OF_NETWORK = 8,
  ERROR_CONTACTED = 9,
  DEDUCTIBLE_REMAINING_RANGE = 10,
  NO_STATE = 11,
  ERROR_OUT_OF_STATE = 12,
  ERROR_OUT_OF_NETWORK_REDIRECT = 13,
  ERROR_OTHER_STATE_REDIRECT = 14,
}

export default PricingCardStatus;
