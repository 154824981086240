/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/* An enumeration. */import {EnumMetadata} from '../metadata'

    /**
    * An enumeration.
    */
export enum MessageType {
    GENERAL = 'GENERAL',
    PROVIDER_CANCELLATION = 'PROVIDER_CANCELLATION',
    APPOINTMENT_TO_INTAKE_CALL = 'APPOINTMENT_TO_INTAKE_CALL',
    PATIENT_BOOKING = 'PATIENT_BOOKING',
    PROVIDER_BOOKING = 'PROVIDER_BOOKING',
    PROVIDER_RESCHEDULE = 'PROVIDER_RESCHEDULE',
    DELEGATED_BOOKING = 'DELEGATED_BOOKING'
}


export const MessageTypeMetadata: EnumMetadata<MessageType> = {
    name: 'MessageType',
    values: {
            GENERAL: 'GENERAL',
            PROVIDER_CANCELLATION: 'PROVIDER_CANCELLATION',
            APPOINTMENT_TO_INTAKE_CALL: 'APPOINTMENT_TO_INTAKE_CALL',
            PATIENT_BOOKING: 'PATIENT_BOOKING',
            PROVIDER_BOOKING: 'PROVIDER_BOOKING',
            PROVIDER_RESCHEDULE: 'PROVIDER_RESCHEDULE',
            DELEGATED_BOOKING: 'DELEGATED_BOOKING'
    }
}
