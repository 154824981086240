import moment from 'moment';
import { useMemo } from 'react';

import { AvailabilityEventResponse } from '@headway/api/models/AvailabilityEventResponse';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { ProviderApi } from '@headway/api/resources/ProviderApi';
import { useQuery, UseQueryOptions } from '@headway/shared/react-query';
import {
  getVisibleAvailabilityEndDate,
  getVisibleAvailabilityStartDate,
} from '@headway/shared/utils/providerAvailabilityUtils';

type QueryKey = readonly ['availabilities', number, string, string];

type QueryOptions = UseQueryOptions<
  Array<AvailabilityEventResponse>,
  unknown,
  Array<AvailabilityEventResponse>,
  QueryKey
>;

export const getProviderAvailabilityQueryKey = ({
  providerId,
  startDate,
  endDate,
}: {
  providerId: number;
  startDate: Date;
  endDate: Date;
}): QueryKey => {
  return [
    'availabilities',
    providerId,
    startDate.toISOString(), // string instead of date so that the query key can be serialized when we set query client's cache server-side
    endDate.toISOString(),
  ] as const;
};

export const useProviderAvailability = (
  provider: ProviderRead,
  options: QueryOptions = {},
  dateRangeStart?: Date | undefined,
  dateRangeEnd?: Date | undefined
) => {
  // create a stable start date that doesn't change on re-renders
  const now = useMemo(() => moment(new Date()).startOf('minute').toDate(), []);

  const startDate =
    dateRangeStart || getVisibleAvailabilityStartDate(now, provider);
  const endDate = dateRangeEnd || getVisibleAvailabilityEndDate(now);

  // the query should be re-fetched if:
  // - the provider changes
  // - the start or end date to query for changes
  const queryKey = getProviderAvailabilityQueryKey({
    providerId: provider.id,
    startDate,
    endDate,
  });

  const queryOptions = {
    ...options,
  };

  return useQuery(
    queryKey,
    () => {
      return ProviderApi.getProviderAvailability(provider.id, {
        date_range_start: startDate.toISOString(),
        date_range_end: endDate.toISOString(),
      });
    },
    queryOptions
  );
};
