import { AvailabilityEventResponse } from '@headway/api/models/AvailabilityEventResponse';
import { ConcreteProviderEventRead } from '@headway/api/models/ConcreteProviderEventRead';
import { BookingAvailabilityEventSelection } from '@headway/shared/types/booking';
import { trackEvent } from '@headway/shared/utils/analytics';

import {
  AnalyticsPatientFlow,
  getAppointmentAnalytics,
} from '../../../utils/analytics';

export const trackSelectAvailabilityStarted = ({
  patientFlow,
}: {
  patientFlow?: AnalyticsPatientFlow;
}): void => {
  trackEvent({
    name: 'Select Availability Started',
    properties: {
      patientFlow,
    },
  });
};

export const trackAvailabilitySlotSelected = ({
  autoSelected = false,
  patientFlow,
}: {
  autoSelected?: boolean;
  patientFlow?: AnalyticsPatientFlow;
}): void => {
  if (autoSelected) {
    return;
  }
  trackEvent({
    name: 'Availability Selected',
    properties: {
      patientFlow,
    },
  });
};

export const trackSelectAvailabilityCompleted = ({
  patientFlow,
}: {
  patientFlow?: AnalyticsPatientFlow;
}): void => {
  trackEvent({
    name: 'Select Availability Completed',
    properties: {
      patientFlow,
    },
  });
};

export const trackBookAppointmentButtonClicked = ({
  availabilityEvent,
  algoliaQueryId,
}: {
  availabilityEvent:
    | AvailabilityEventResponse
    | BookingAvailabilityEventSelection;
  algoliaQueryId: string;
}): void => {
  trackEvent({
    name: 'Book Appointment Button Clicked',
    properties: {
      ...getAppointmentAnalytics(availabilityEvent),
      providerId: availabilityEvent.providerId,
      ctaButtonCopy: '',
      algoliaQueryId: algoliaQueryId,
    },
  });
};

export const trackBookAppointmentStarted = ({
  availabilityEvent,
}: {
  availabilityEvent:
    | AvailabilityEventResponse
    | BookingAvailabilityEventSelection;
}): void => {
  trackEvent({
    name: 'Book Appointment Started',
    properties: {
      ...getAppointmentAnalytics(availabilityEvent),
      providerId: availabilityEvent.providerId,
    },
  });
};

export const trackBookAppointmentCompleted = ({
  providerEvent,
  algoliaQueryId,
}: {
  providerEvent: ConcreteProviderEventRead;
  algoliaQueryId?: string;
}): void => {
  trackEvent({
    name: 'Book Appointment Completed',
    properties: {
      providerId: providerEvent.providerId,
      providerAppointmentId: providerEvent.providerAppointment?.id,
      providerEventId: providerEvent.id,
      appointmentType: providerEvent.type.toLowerCase() as
        | 'intake_call'
        | 'appointment',
      algoliaQueryId: algoliaQueryId,
    },
  });
};
