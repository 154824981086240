import { css } from '@emotion/react';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import _ from 'lodash';
import sortBy from 'lodash/sortBy';
import uniqWith from 'lodash/uniqWith';
import moment from 'moment';
import { useState } from 'react';

import { AvailabilityEventResponse } from '@headway/api/models/AvailabilityEventResponse';
import { ContentText } from '@headway/helix/ContentText';
import {
  InPerson as InPersonIcon,
  MobilePhone as MobilePhoneIcon,
  VideoChat as VideoChatIcon,
} from '@headway/icons/dist/provider';
import { theme } from '@headway/ui/theme';

export const AVAILABILITY_BLOCK_MIN_WIDTH_PIXELS = 100;
const DEFAULT_OPENINGS_PER_DAY_SHOWN = 4;

const availabilityColumnsContainerCss = (daysPerPage: number) => css`
  display: grid;
  grid-template-columns: repeat(
    ${daysPerPage},
    minmax(${AVAILABILITY_BLOCK_MIN_WIDTH_PIXELS}px, ${100 / daysPerPage}%)
  );
  flex-grow: 1;
`;
const availabilityColumnCss = css`
  padding: ${theme.space.xs2};
`;
const availabilityWithNavContainerCss = css`
  align-items: flex-start;
  display: flex;
  flex-wrap: nowrap;
`;

const openingAccordionContainerCss = css`
  margin-bottom: ${theme.space.xs2};
  .MuiAccordion-root.Mui-expanded {
    background-color: ${theme.color.primaryBackground};
    border: 1px solid ${theme.color.primary};
  }
`;
const openingAccordionCss = css`
  border: 1px solid ${theme.color.border};
  box-shadow: none;
  font-size: ${theme.fontSize.xs};
  width: auto;
  .MuiAccordionSummary-content,
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 0;
  }
  .MuiAccordionSummary-root,
  .MuiAccordionSummary-root.Mui-expanded {
    min-height: 1.75rem;
  }
  .MuiAccordionSummary-root.Mui-expanded {
    outline: none !important;
  }
`;
const openingDetailsCss = css`
  color: ${theme.color.textGray};
  display: flex;
  font-size: ${theme.fontSize.xs};
  flex-direction: column;
  margin-top: 0;
  padding: 0 ${theme.space.xs} ${theme.space.xs} ${theme.space.xs};
  text-align: center;
`;

const openingSummaryLocationIconCss = css`
  font-size: ${theme.fontSize.base};
  margin-left: ${theme.space.xs3};
  & path {
    fill: ${theme.color.primaryMedium};
  }
`;
const openingSummaryTimeCss = css`
  align-items: center;
  display: flex;
  gap: ${theme.space.xs2};
  justify-content: center;
  width: 100%;
`;

interface BookingAvailabilitySelectorProps {
  daysPerPage: number;
  providerAvailabilities: AvailabilityEventResponse[];
  onAvailabilitySelect: ({
    availability,
    autoSelected,
  }: {
    availability: AvailabilityEventResponse;
    autoSelected: boolean;
  }) => void;
  requiresIntake: boolean;
  /**
   * if provided, will display the availability in the given timezone
   * otherwise, it will display in the timezone of the providerAvailabilities
   */
  displayTimezone?: string;
}

const dateNavButtonCss = (position: 'left' | 'right') => css`
  margin-left: ${position === 'right' ? `-${theme.space.xs}` : 0};
  margin-right: ${position === 'left' ? `-${theme.space.xs}` : 0};
  top: ${theme.space.xs};
  svg {
    height: ${theme.fontSize.base};
    width: ${theme.fontSize.base};
  }
`;

interface AvailabilityColumnProps {
  availabilities: AvailabilityEventResponse[];
  onAvailabilitySelect: ({
    availability,
    autoSelected,
  }: {
    availability: AvailabilityEventResponse;
    autoSelected: boolean;
  }) => void;
  requiresIntake: boolean;
  selectedAvailability?: AvailabilityEventResponse;
  displayTimezone?: string;
}

const availabilitiesAreTheSame = (
  availabilityA?: AvailabilityEventResponse,
  availabilityB?: AvailabilityEventResponse
) =>
  !!availabilityA &&
  !!availabilityB &&
  moment(availabilityA.startDate).isSame(moment(availabilityB.startDate)) &&
  availabilityA.providerAddressId === availabilityB.providerAddressId;

const AvailabilityColumn = ({
  availabilities,
  onAvailabilitySelect,
  requiresIntake,
  selectedAvailability,
  displayTimezone,
  ...props
}: AvailabilityColumnProps) => {
  const [showMore, setShowMore] = useState(false);

  if (!availabilities) {
    return null;
  }

  const currentDay = availabilities[0].startDate;
  const sortedAndDedupedAvailability = sortBy(
    uniqWith(availabilities, availabilitiesAreTheSame),
    (avail: AvailabilityEventResponse) => moment(avail.startDate).toDate()
  );

  // 'More' button takes same space as one extra availability,
  // so when number of availabilities is 1 more than default, still show all
  const shouldShowAllAvailabilities =
    showMore ||
    sortedAndDedupedAvailability.length <= DEFAULT_OPENINGS_PER_DAY_SHOWN + 1;
  const availabilitiesToShow = shouldShowAllAvailabilities
    ? sortedAndDedupedAvailability
    : sortedAndDedupedAvailability.slice(0, DEFAULT_OPENINGS_PER_DAY_SHOWN);

  return (
    <div key={currentDay} {...props}>
      {/** TODO: PATIENT-1634 add timezone selected */}
      {/** TODO: PATIENT-1634 add session length */}
      <div className="text-center">
        <ContentText>{moment(currentDay).format('ddd')}</ContentText>
        <div>
          <ContentText>{moment(currentDay).format('MMM D')}</ContentText>
        </div>
      </div>

      <div className="mt-4">
        {availabilitiesToShow.map((availability) => (
          <div css={openingAccordionContainerCss}>
            <Accordion
              css={openingAccordionCss}
              expanded={availabilitiesAreTheSame(
                availability,
                selectedAvailability
              )}
              key={moment(availability.startDate).toISOString()}
            >
              <AccordionSummary
                onClick={() =>
                  onAvailabilitySelect({ availability, autoSelected: false })
                }
              >
                <div
                  css={openingSummaryTimeCss}
                  data-testid="bookingSlotExpand"
                >
                  {`${displayTimezone ? moment(availability.startDate).tz(displayTimezone).format('h:mma') : moment(availability.startDate).format('h:mma')}`}{' '}
                  {requiresIntake ? (
                    <MobilePhoneIcon css={openingSummaryLocationIconCss} />
                  ) : (
                    <div className="flex items-center gap-[3px]">
                      {/** TODO: PATIENT-1634 Replace these icons with helix */}
                      {availability.telehealth && <VideoChatIcon width={12} />}
                      {!!availability.providerAddressId && (
                        <InPersonIcon width={12} />
                      )}
                    </div>
                  )}
                </div>
              </AccordionSummary>

              <AccordionDetails css={openingDetailsCss}>
                <div>
                  {moment
                    .duration(
                      moment(availability.endDate).diff(availability.startDate)
                    )
                    .asMinutes()}{' '}
                  min
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        ))}
        {!shouldShowAllAvailabilities && (
          <div css={openingAccordionContainerCss}>
            <Accordion css={openingAccordionCss}>
              <AccordionSummary onClick={() => setShowMore(true)}>
                <div css={{ margin: 'auto' }}>More</div>
              </AccordionSummary>
            </Accordion>
          </div>
        )}
      </div>
    </div>
  );
};

const BookingAvailabilitySelector = ({
  daysPerPage,
  providerAvailabilities,
  onAvailabilitySelect,
  requiresIntake,
  displayTimezone,
}: BookingAvailabilitySelectorProps) => {
  const [availabilityPageNumber, setAvailabilityPageNumber] = useState(0);
  const [selectedAvailability, setSelectedAvailability] =
    useState<AvailabilityEventResponse>();
  const daysRange = _.range(
    daysPerPage * availabilityPageNumber,
    daysPerPage * availabilityPageNumber + daysPerPage
  );

  const providerAvailabilitiesByDay = providerAvailabilities
    ? _.groupBy(providerAvailabilities || {}, (availability) =>
        moment(availability.startDate).format('YYYY-MM-DD')
      )
    : {};
  const daysWithAvailability = Object.keys(providerAvailabilitiesByDay);

  return (
    <div css={availabilityWithNavContainerCss}>
      <IconButton
        css={dateNavButtonCss('left')}
        disabled={availabilityPageNumber === 0}
        onClick={() => setAvailabilityPageNumber(availabilityPageNumber - 1)}
        size="small"
        aria-label="Previous Week"
      >
        <ArrowBackIos />
      </IconButton>
      <div css={availabilityColumnsContainerCss(daysPerPage)}>
        {daysRange.map((dayIndex: number) => (
          <AvailabilityColumn
            key={`availability-day-${dayIndex}`}
            availabilities={
              (
                providerAvailabilitiesByDay as {
                  [date: string]: AvailabilityEventResponse[];
                }
              )[daysWithAvailability[dayIndex]]
            }
            css={availabilityColumnCss}
            onAvailabilitySelect={({ availability, autoSelected }) => {
              onAvailabilitySelect({ availability, autoSelected });
              setSelectedAvailability(availability);
            }}
            requiresIntake={requiresIntake}
            selectedAvailability={selectedAvailability}
            displayTimezone={displayTimezone}
          />
        ))}
      </div>
      <IconButton
        css={dateNavButtonCss('right')}
        data-testid="bookingNextButton"
        disabled={!daysWithAvailability[daysRange[daysRange.length - 1] + 1]}
        onClick={() => setAvailabilityPageNumber(availabilityPageNumber + 1)}
        size="small"
        aria-label="Next Week"
      >
        <ArrowForwardIos />
      </IconButton>
    </div>
  );
};

export default BookingAvailabilitySelector;
