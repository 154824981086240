import React from 'react';

import {
  InsuranceReadinessIssueInsufficientOrIncorrectInfo,
  InsuranceReadinessIssueInsufficientOrIncorrectInfoType,
} from '@headway/api/models/InsuranceReadinessIssueInsufficientOrIncorrectInfo';
import { LookupStatus } from '@headway/api/models/LookupStatus';
import { useFlag } from '@headway/feature-flags/react';
import { Button } from '@headway/helix/Button';
import { ContentText } from '@headway/helix/ContentText';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { SITE_MESSAGING_OPPS } from '@headway/shared/FeatureFlags/flagNames';
import {
  getUseUserAppointmentReadinessQueryKey,
  useAppointmentReadiness,
} from '@headway/shared/hooks/useAppointmentReadiness';
import { useQueryClient } from '@headway/shared/react-query';
import { trackEvent, trackPageView } from '@headway/shared/utils/analytics';
import {
  getVerificationStatus,
  UserVerificationStatus,
} from '@headway/shared/utils/userVerificationStatus';
import { BenefitsGrid } from '@headway/ui/BenefitsGrid';
import { ContactFormInsuranceAlerts } from '@headway/ui/InsuranceEligibilityAlerts';

import { IAuthStore, IUiStore, withStores } from '../../stores/withStores';
import { isInsuranceUser } from '../Account/modules/Insurance';
import { InsuranceVerificationAlert } from '../InsuranceVerificationAlert';

const InsufficientOrIncorrectInfoAlert = ({
  updateInsuranceInfo,
  isHwInputError,
  userId,
}: {
  updateInsuranceInfo: () => void;
  isHwInputError: boolean;
  userId: number;
}) => {
  return (
    <GuidanceCard variant="error" layout="vertical">
      <ContentText>
        <strong>Likely typo in insurance details.</strong> Check to make sure
        your insurance details match the details on your insurance card.
        Usually, it just means fixing one of the following issues:
        <ul className={'mb-0'}>
          <li>
            Make sure your name is spelled exactly the same way as on your card
          </li>
          <li>Double check your date of birth</li>
          <li>Confirm your Member ID matches your card exactly</li>
        </ul>
        Still having trouble? Reach out to your insurer directly.
      </ContentText>
      <Button
        variant="link"
        onPress={() => {
          trackEvent({
            name: 'Inactive Insurance Messaging CTA Clicked',
            properties: {
              lookupStatus: LookupStatus.INSUFFICIENT_OR_INCORRECT_INFORMATION,
              userId: userId,
              confettiHumanInputErrorExperimentGroup: 'treatment',
              confettiHumanInputErrorSurfacedBy: isHwInputError ? 'HW' : 'CHC',
            },
          });
          updateInsuranceInfo();
        }}
      >
        Update insurance information
      </Button>
    </GuidanceCard>
  );
};

type Props = {
  AuthStore: IAuthStore;
  onBack: () => void;
  onConfirm: () => void;
  UiStore: IUiStore;
};

const InsuranceLookupIssueFoundImpl: React.FC<Props> = ({
  AuthStore,
  onBack,
}) => {
  const user = AuthStore.user;
  const queryClient = useQueryClient();

  React.useEffect(() => {
    trackEvent({
      name: 'Insurance_lookup_failed',
      properties: {},
    });
  }, []);

  React.useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: getUseUserAppointmentReadinessQueryKey(user.id),
    });
  }, [user.id, queryClient]);

  const accountVerificationStatus = getVerificationStatus(user);
  const isInsurance = isInsuranceUser(user);

  const appointmentReadinessQuery = useAppointmentReadiness(user.id);

  const insufficientInfoIssue = (
    appointmentReadinessQuery.data?.insurance || []
  ).find(
    (issue) =>
      issue.type ===
      InsuranceReadinessIssueInsufficientOrIncorrectInfoType.INSUFFICIENT_OR_INCORRECT_INFORMATION
  ) as InsuranceReadinessIssueInsufficientOrIncorrectInfo | undefined;

  const isHwInputError =
    !!insufficientInfoIssue?.patientMismatchInputFields &&
    insufficientInfoIssue?.patientMismatchInputFields.length > 0;

  React.useEffect(() => {
    if (insufficientInfoIssue) {
      trackPageView({
        name: 'Inactive Insurance Messaging Viewed',
        properties: {
          lookupStatus: LookupStatus.INSUFFICIENT_OR_INCORRECT_INFORMATION,
          userId: user.id,
          confettiHumanInputErrorExperimentGroup: 'treatment',
          confettiHumanInputErrorSurfacedBy: isHwInputError ? 'HW' : 'CHC',
        },
      });
    }
  }, [user.id]);

  const isHumanErrorBannerEnabled = insufficientInfoIssue;

  const siteMessagingOpps = useFlag(SITE_MESSAGING_OPPS, false);

  if (appointmentReadinessQuery.isFetching) {
    return null;
  }

  return (
    <>
      {accountVerificationStatus ===
        UserVerificationStatus.REGISTERED_AND_VERIFIED && isInsurance ? (
        <div className={'mb-4'}>
          {isHumanErrorBannerEnabled ? (
            <InsufficientOrIncorrectInfoAlert
              updateInsuranceInfo={onBack}
              isHwInputError={isHwInputError}
              userId={user.id}
            />
          ) : (
            <ContactFormInsuranceAlerts
              insurance={user.activeUserInsurance}
              insuranceLocation={'below'}
              baseUrl={process.env.NEXT_PUBLIC_CARE_URL || ''}
              contactUs
              isHumanErrorBannerEnabled={isHumanErrorBannerEnabled}
              onOpenInsuranceModal={onBack}
              isSiteMessagingOppsEnabled={siteMessagingOpps}
              isHwInputError={isHwInputError}
            />
          )}
        </div>
      ) : (
        <InsuranceVerificationAlert />
      )}
      <BenefitsGrid user={user} />
      <div className="flex justify-end pt-12">
        <Button variant="primary" type="button" onPress={onBack}>
          Go back and edit
        </Button>
      </div>
    </>
  );
};

export default withStores(InsuranceLookupIssueFoundImpl);
