/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/* An enumeration. */import {EnumMetadata} from '../metadata'

    /**
    * An enumeration.
    */
export enum UserInsuranceDetailsState {
    FULL = 'FULL',
    PARTIAL = 'PARTIAL',
    NOT_ON_FILE = 'NOT_ON_FILE',
    NOT_ON_FILE_PAUSED = 'NOT_ON_FILE_PAUSED'
}


export const UserInsuranceDetailsStateMetadata: EnumMetadata<UserInsuranceDetailsState> = {
    name: 'UserInsuranceDetailsState',
    values: {
            FULL: 'FULL',
            PARTIAL: 'PARTIAL',
            NOT_ON_FILE: 'NOT_ON_FILE',
            NOT_ON_FILE_PAUSED: 'NOT_ON_FILE_PAUSED'
    }
}
